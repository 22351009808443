import { categoryConstants, initialDtataConstants, landingConstants, orderConstants, productConstants, projectConstants, withdrawConstants } from "./constants";
import axios from "../helpers/axios";



export const getInitialData = () =>{

    return async dispatch => {
     
        const res = await axios.post(`/initialData`);

        if(res.status === 200){

            const {categories, products, orders, withdraw, projects, LandingPage } = res.data;

            dispatch({
                type: categoryConstants.GET_ALL_CATEGORIES_SUCCESS,
                payload: { categories }
            });

            dispatch({
                
                type: productConstants.GET_ALL_PRODUCTS_SUCCESS,
                payload: { products }

            });

            dispatch({
                
                type: projectConstants.GET_ALL_PROJECT_SUCCESS,
                payload: { projects }

            });

            dispatch({
                
                type: orderConstants.GET_CUSTOMER_ORDER_SUCCESS,
                payload: { orders }

            });

            dispatch({
                
                type: withdrawConstants.GET_ALL_WITHDRAW_SUCCESS,
                payload: { withdraw }

            });
            dispatch({
                
                type: landingConstants.LANDING_SUCCESS,
                payload: { LandingPage }

            });

        }

        console.log(res);
    }

}