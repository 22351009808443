import React from 'react';
import Input from "../../../components/UI Component/inputs";
import Modal from "../../../components/UI Component/Modals";
import { Jumbotron, Row, Col, Container } from "react-bootstrap";


const AddCategoriesModal = (props) =>{

    const {

        show,
        handleClose,
        modalTitle,
        categoryName,
        setCategoryName,
        parentCategoryId,
        setParentCategoryId,
        categoryList,
        handleCategoryImage,
        onSubmit

    } = props;
      
    return(
        <Modal
        show={show}
        handleClose={handleClose}
        onSubmit={onSubmit}
        modalTitle={modalTitle}
      >

          <Row>
              <Col>
              <Input
          value={categoryName}
          placeholder={`category name`}
          onChange={(e) => setCategoryName(e.target.value)}
          className="form-control-sm"
        />
              </Col>
           
          </Row>
 

     
      </Modal>
    );
  }

  export default AddCategoriesModal;