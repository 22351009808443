import { bannerConstants } from "../actions/constants";

const initialState ={
    banners : []
};

export default (state = initialState, action) =>{
    switch(action.type){
        case bannerConstants.GET_ALL_BANNERS_SUCCESS:
            state={
                ...state,
                banners: action.payload.banners
            }
            break;
    }
    return state;
}