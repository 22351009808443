import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout";
import { Container, Row, Col, Table } from "react-bootstrap";
import Input from "../../components/UI Component/inputs";
import Modal from "../../components/UI Component/Modals";
import { useSelector, useDispatch } from "react-redux";
import { addBanner, deleteBannerById, getBanners } from "../../actions";
import "./banner.css";
import { generatePublicUrl } from "../../urlConfig";
import { IoIosAdd, IoIosApps, IoIosBarcode, IoIosBasket, IoIosCash, IoIosPulse } from 'react-icons/io';

/**
 * @author
 * @function Products
 **/

const Products = (props) => {
  const [title, setTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [skuCode, setSku] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [banners, setBanners] = useState([]);
  const [show, setShow] = useState(false);
  const [productDetailModal, setProductDetailModal] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const category = useSelector((state) => state.category);
  const banner = useSelector((state) => state.banner);
  const order = useSelector(state=> state.order);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  console.log(banner);

  const productLength = banner.banners.length;

  useEffect(() =>{
    dispatch(getBanners())
  }, [])

  useEffect(() =>{

 
    if(!banner.laoding){
        setTitle('');
        setDescription('');
      
    }

},[banner]);

 const lastItem =  useMemo(()=>{
  
  let length = banner.banners.length;
  if(length)  return banner.banners[length-1];
  return {}
 

 },[banner.banners])



 

  const handleClose = () => {
    setShow(false);
  };

  const submitProductForm = () => {
    const form = new FormData();
    form.append("title", title);
    
    form.append("description", description);

    if(title == ""){
      alert("name is required");
      return;
    }
    else if(description == ""){
      alert("description is required");
      return;
    }
  

    for (let pic of banners) {
      form.append("banners", pic);
    }

    dispatch(addBanner(form)).then(() => setShow(false), getBanners());
  };
  const handleShow = () => setShow(true);



  const handleProductPictures = (e) => {
    setBanners([...banners, e.target.files[0]]);
  };

  const renderProducts = () => {

    return (
      <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
          
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {banner.banners.length > 0
            ? banner.banners.filter((pd, index)=>{
            if(searchTerm)  
            {
              return pd?.[searchBy]?.includes(searchTerm);
            }
            else{
              return true;
            }
           

            }).map((nebanner, index) => (
                <tr key={nebanner._id}>
                  <td>{index + 1}</td>
                  <td>{nebanner.title}</td>
                  <td>{nebanner.description}</td>
                 
                  <td className="action-btn-container">
                    <button className="info-product" onClick={() => showProductDetailsModal(nebanner)}>
                      info
                    </button>
                    <button className="del-product"
                      onClick={() => {
                        const hid = nebanner._id
                    
                        dispatch(deleteBannerById(hid));
                        
                      }}
                    >
                      del
                    </button>

                    
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    );
  };

  const renderAddProductModal = () => {
    return (
      <Modal
        show={show}
        handleClose={handleClose}
        modalTitle={"Add New Product"}
        onSubmit={submitProductForm}
      >
        <Input
          label="title"
          value={title}
          placeholder={`Product Name`}
          onChange={(e) => setTitle(e.target.value)}
        />
       
     
        <Input
          label="Description"
          value={description}
          placeholder={`Description`}
          onChange={(e) => setDescription(e.target.value)}
        />
      
        {banners.length > 0
          ? banners.map((pic, index) => (
              <div key={index}>{pic.name}</div>
            ))
          : null}
        <input
          type="file"
          name="banners"
          onChange={handleProductPictures}
        />
      </Modal>
    );
  };

  const handleCloseProductDetailsModal = () => {
    setProductDetailModal(false);
  };

  const showProductDetailsModal = (newbanner) => {
    setProductDetails(newbanner);
    setProductDetailModal(true);
  };

  const renderProductDetailsModal = () => {
    if (!productDetails) {
      return null;
    }

    return (
      <Modal
        show={productDetailModal}
        handleClose={handleCloseProductDetailsModal}
        modalTitle={"Product Details"}
        size="lg"
      >
        <Row>
          <Col md="6">
            <label className="key">Name</label>
            <p className="value">{productDetails.title}</p>
          </Col>
       
        </Row>
    
        <Row>
          <Col md="6">
            <label className="key">Description</label>
            <p className="value">{productDetails.description}</p>
          </Col>
     
        </Row>
        <Row>
          <Col>
            <label className="key">Product Pictures</label>
            <div style={{ display: "flex" }}>
              {productDetails.banners.map((picture) => (
                <div className="productImgContainer">
                {/* {JSON.stringify(picture)} */}
                  <img src={generatePublicUrl(picture.img)} alt=""/>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Modal>
    );
  };
  return (
    <Layout sidebar>
      <Container>
      {/* {
         [lastItem].map((item, index) => 
          
          <Row>
      
          <Col md={4}>
          <div className="card-counter primary">
         <IoIosApps className="card-icon" />
         <span className="count-numbers">{item.name}</span>
         <span className="count-name">Recently added</span> 
       
      
       </div>
          </Col>
          <Col md={4}>
          <div className="card-counter danger">
          <IoIosApps className="card-icon" />
         <span className="count-numbers">{productLength}</span>
         <span className="count-name">Total Products</span>
       </div>
          </Col>
          <Col md={4}>
          <div className="card-counter success">
          <IoIosApps className="card-icon" />
         <span className="count-numbers"><div>{orderLength}</div></span>
         <span className="count-name">Orders</span>
       </div>
          </Col>
        </Row>
            
          )
        } */}
    
        {/* <Row>
          <Col md={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }} className="actionBtnContainer">
              <h3>Products</h3>

              <button  onClick={handleShow}>Add</button>
            </div>
          </Col>
        </Row> */}

<Container>
      <Row>
         <Col lg={12}> 
         
           <div className="home-banner" style={{marginRight:'-10px', display: 'flex', justifyContent:"space-between", alignItems: 'center'}}>
           <div>
           <h2>Hello, {auth.user.fullName}</h2>
             <h5>Welcome To product section</h5>
           </div>
           <div className="flexRow1" >
              <select className="select-container" defaultValue={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                <option value={`name`}>name</option>
                <option value={`skuCode`}>sku code</option>
              </select>
              <Input
          
          value={searchTerm}
          placeholder={`search`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
       
        
              </div>
          
   
           </div>
         </Col>
       </Row>

      </Container>
        {/* <Row  style={{marginTop: '50px'}}>
          <Col md={4}>
          <h3 style={{paddingLeft: '17px', fontSize: '30px', fontWeight: 'bolder'}}>PRODUCTS</h3>
          </Col>
          <Col md={4}></Col>
          <Col md={4}>
              <div className="flexRow1">
              <select className="select-container" defaultValue={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                <option value={`name`}>name</option>
                <option value={`skuCode`}>sku code</option>
              </select>
              <Input
          
          value={searchTerm}
          placeholder={`search`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
              </div>
          </Col>
        </Row> */}
        <Row>
          {/* <Col>{renderProducts()}</Col> */}
        </Row>
      </Container>
      <Container >
        {
              [lastItem].map((item, index) =>        
              <Row>
              <Col md={4}>
               
                 
                    <div className="product-new-card-container product-card-b">
                      <div className="product-card-body">
                        <IoIosAdd className="product-icons" onClick={handleShow} />
                        <h5 className="text-muted fw-normal mt-0">Add Banner</h5>
                      </div>
                    </div>
                  
                  </Col>
             
                  <Col md={4}>
               
               
               <div className="product-new-card-container1 product-card-b1">
               <div className="rec-head">
                 <h5>Recently Added</h5>
                 <IoIosBasket className="phead-icon"  />
               </div>
            
                 <div className="product-card-body1">
                   
                   {/* <IoIosAdd className="product-icons" onClick={handleShow} /> */}
                  
                   <h5 className="text-muted1 fw-normal1 mt-01" style={{fontSize: "17px"}}>{item.title}, {item.description}</h5>
                 </div>
               </div>
             
             </Col>
                <Col md={4}>
                    <div className="product-new-card-container product-card-b">
                      <div className="product-card-body">
                        <div className="product-icons p-font"> <h1 style={{paddingTop: '25px'}}>{productLength}</h1>  </div>
                        <h5 className="text-muted fw-normal mt-0">Total Banners</h5>
                      </div>
                    </div>
                 
               
              
              </Col>
              {/* <Col md={6}>
              <div className="product-new-card-container1 product-card-b1">
                      <div className="product-card-body1">
                      
                       
                       {renderProducts()}
                      
                      </div>
                    </div>
                   
              </Col> */}
            </Row>
            
            
            )
        }
  
 <Row>
 <Col md={12}>
              <div className="renderPr">
                      <div>
                      
                       
                       {renderProducts()}
                       
                   
                      </div>
                    </div>
                   
              </Col> 
 </Row>
        <br />
        <br />
      </Container>
      {renderAddProductModal()}
      {renderProductDetailsModal()}
    </Layout>
  );
};

export default Products;