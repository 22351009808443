import { withdrawConstants } from "./constants";
import axios from "../helpers/axios";

export const getWitdraw = () => {
    return async (dispatch) => {
      try {
        dispatch({ type: withdrawConstants.GET_ALL_WITHDRAW_REQUIEST });
        const res = await axios.post(`/get-pyment-request`);
        if (res.status === 201) {
          const { withdraw } = res.data;
          console.log(withdraw, "from actoin");
          dispatch({
            type: withdrawConstants.GET_ALL_WITHDRAW_SUCCESS,
            payload: { withdraw },
          });
        } else {
          dispatch({ type: withdrawConstants.GET_ALL_WITHDRAW_FAILURE });
        }
      } catch (error) {
        console.log(error);
      }
    };
  };