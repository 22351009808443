import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import productReducer from "./products.reducer";
import orderReducer from "./orders.reducer";
import categoryReducer from "./category.reducer";
import pageReducer from "./page.reducer";
import bannerReducer from "./banner.reducer";
import withdrawReducer from "./withdraw.reducer";
import projectReducer from "./project.reducer";
import landingReducer from "./landing.reducer";
const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    category: categoryReducer,
    product: productReducer,
    order: orderReducer,
    withdraw: withdrawReducer,
    page:pageReducer,
    banner:bannerReducer,
    project: projectReducer,
    landing: landingReducer
});

export default rootReducer;