import React, { useEffect, useState } from 'react'
import  Modal  from '../../components/UI Component/Modals';
import Layout from '../../components/Layout';
import {Container, Row, Col, Table} from 'react-bootstrap';
import Input from '../../components/UI Component/inputs';
import createCategoryHelper from '../../helpers/categoryHelper';
import { useDispatch, useSelector } from 'react-redux';
import { addBlog, addCounc, addLanding, addService, createPage, getLanding } from '../../actions';
import {IoMdAdd} from "react-icons/io"
import "./style.css";
import { generatePublicUrl } from '../../urlConfig';
import axios from "../../helpers/axios";
import moment from 'moment';

/**
* @author
* @function NewPage
**/

const NewPage = (props) =>{
    const [createModal, setCreateModal] = useState(false);
    const [createServiceModal, setServiceCreateModal] = useState(false);
    const [createBlogModal, setBlogCreateModal] = useState(false);
    const [createCouncllorModal, setCouncllorCreateModal] = useState(false);
    const [title, setTitle] = useState('');
    const [blog, setBlog] = useState('');
    const [blogDesc, setBlogDesc] = useState('');
    const [images1, setImages1] = useState([]);
    const [counc, setCounc] = useState('');
    const [councDesc, setCouncDesc] = useState('');
    const [degi, setDegi] = useState('');
    const [images2, setImages2] = useState([]);
    const [service, setService] = useState('');
    const [serviceDesc, setServiceDesc] = useState('');
    const [images, serImages] = useState([]);
    const [titleCarrer, setTitleCarrer] = useState('');
    const category = useSelector(state => state.category);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [desc, setDesc] = useState('');
    const [descCarrer, setDescCarrer] = useState('');
    const [descSocial, setDescSocial] = useState('');
    const [type, setType] = useState('');
    const [banners, setBanners] = useState([]);
    const [products, setProducts] = useState([]);
    const dispatch = useDispatch();
    const page = useSelector(state => state.page);
    const auth = useSelector(state => state.auth);
    const landing = useSelector(state => state.landing);
    // const category = useSelector(state => state.categories)

    useEffect(() => {
       setCategories(createCategoryHelper(category.categories));
      
    }, [category]);


    console.log(landing);
    useEffect(() => {
        dispatch(getLanding())
    },[landing.landings])
    // useEffect(() =>{

    //     console.log(page);
    //     if(!page.laoding){
    //         setCreateModal(false);
    //         setTitle('');
    //         setTitleCarrer('');
    //         setTitleSocial('');
    //         setDescCarrer('');
    //         setDescSocial('');
    //         setCategoryId('');
    //         setDesc('');
    //         setProducts([]);
    //         setBanners([]);
    //     }

    // },[landing.landings]);



    console.log("categories", categories);

    const onCategoryChange = (e) =>{
      categories.find(category => category.value == e.target.value);
      setCategoryId(e.target.value);
        setType(category.type);
    }


    const handleBannerImages = (e) =>{
         
        setBanners([...banners, e.target.files[0]]);
    }

    const handleServiceImages = (e) =>{
         
        serImages([...images, e.target.files[0]]);
    }

    const handleProductImages = (e) =>{
        setProducts([...products, e.target.files[0]]);
    }

    
    const handleBlogImages = (e) =>{
        setImages1([...images1, e.target.files[0]]);
    }

    const handleCouncImages = (e) =>{
        setImages2([...images2, e.target.files[0]]);
    }

    const submitPageForm =(e) =>{

       
      // e.target.preventDefault();
        if(title === ""){
            alert("title is required");
            setCreateModal(false);
            return;
        }
        const form = new FormData();
        form.append('headBannerTitle1', title);
        form.append('landingId', landing.landings[0]._id)
        form.append('headBannerSubtitle1', desc);
        form.append('headBannerTitle2', titleCarrer);
        form.append('headBannerSubtitle2', descCarrer);
        banners.forEach((banner, index) => {
                form.append('img1', banner)
        });
        products.forEach((product, index) => {
            form.append('img2', product)
    });

    console.log(form);

    dispatch(addLanding(form)).then(() => setCreateModal(false));


    }



    const submitService =(e) =>{

       
      
          const form = new FormData();
          form.append('serviceTtitle', service);
          form.append('landingId', landing.landings[0]._id)
          form.append('serviceDescription', serviceDesc);
          images.forEach((image, index) => {
                  form.append('image', image)
          });
  
      console.log(form);
  
      dispatch(addService(form)).then(() => setServiceCreateModal(false));
  
  
      }


      const submitBlogs =(e) =>{

       
      
        const form = new FormData();
        form.append('blogTitle', blog);
        form.append('landingId', landing.landings[0]._id)
        form.append('blogDescription', blogDesc);
        images1.forEach((image1, index) => {
                form.append('image', image1)
        });

    console.log(form);

    dispatch(addBlog(form)).then(() => setBlogCreateModal(false));


    }



    const submitCounc =(e) =>{

       
      
        const form = new FormData();
        form.append('councillerName', counc);
        form.append('landingId', landing.landings[0]._id)
        form.append('aboutCounciller', councDesc);
        form.append('councillerDesignation', degi);
        images2.forEach((image2, index) => {
                form.append('image', image2)
        });

    console.log(form);

    dispatch(addCounc(form)).then(() => setCouncllorCreateModal(false));


    }

    const deleteBannerById =async(id) => {
         console.log(id);

           axios.post(`https://api.risoapp.com/api/admin/headbanner/${id}`).then(res => {
            if(res) {
                dispatch(getLanding());
            }
           })
    }


    const deleteServiceById =async(id) => {
        console.log(id);

          axios.post(`https://api.risoapp.com/api/admin/ourServices/${id}`).then(res => {
           if(res) {
               dispatch(getLanding());
           }
          })
   }


   const deleteBlogeById =async(id) => {
    console.log(id);

      axios.post(`https://api.risoapp.com/api/admin/blog/${id}`).then(res => {
       if(res) {
           dispatch(getLanding());
       }
      })
}


const deleteCouncById =async(id) => {
    console.log(id);

      axios.post(`https://api.risoapp.com/api/admin/ourCounciller/${id}`).then(res => {
       if(res) {
           dispatch(getLanding());
       }
      })
}



    const renderCreatePageModal =() =>{
        return(

    
            <Modal  
                show = {createModal}
                modalTitle = {"Create Banner Section"}
                handleClose = {() => setCreateModal(false)}
                onSubmit = {submitPageForm}
            
            >
                <Container>

              
                <h6>Education</h6>
                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {title}
                       onChange = {(e) => setTitle(e.target.value)}
                       placeholder = {'Page Title'}
                       />
                    </Col>
                </Row>

                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {desc}
                       onChange = {(e) => setDesc(e.target.value)}
                       placeholder = {'Page Description'}
                       />
                    </Col>
                    <Col>
                       <Input type="file"
                        className="form-control"
                       name="img1"
                       onChange = {handleBannerImages}
                       />
                    </Col>
                </Row>
        
                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {titleCarrer}
                       onChange = {(e) => setTitleCarrer(e.target.value)}
                       placeholder = {'Discover Title'}
                       />
                    </Col>
                    
                </Row>

                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {descCarrer}
                       onChange = {(e) => setDescCarrer(e.target.value)}
                       placeholder = {'Discover Description'}
                       />
                    </Col>
                    <Col>
                       <Input type="file"
                        className="form-control"
                       name="img2"
                       onChange = {handleProductImages}
                       />
                    </Col>
                </Row>
                </Container>   
            </Modal>
        );
    }



    //service render

    const renderCreateServiceModal =() =>{
        return(

    
            <Modal  
                show = {createServiceModal}
                modalTitle = {"Create Service Section"}
                handleClose = {() => setServiceCreateModal(false)}
                onSubmit = {submitService}
            
            >
                <Container>

              
                <h6>Service</h6>
                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {service}
                       onChange = {(e) => setService(e.target.value)}
                       placeholder = {'Service Title'}
                       />
                    </Col>
                </Row>

                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {serviceDesc}
                       onChange = {(e) => setServiceDesc(e.target.value)}
                       placeholder = {'Service Description'}
                       />
                    </Col>
                    <Col>
                       <Input type="file"
                        className="form-control"
                       name="image"
                       onChange = {handleServiceImages}
                       />
                    </Col>
                </Row>
        
           

            
                </Container>   
            </Modal>
        );
    }



    const renderCreateBlogModal =() =>{
        return(

    
            <Modal  
                show = {createBlogModal}
                modalTitle = {"Create Blog Section"}
                handleClose = {() => setBlogCreateModal(false)}
                onSubmit = {submitBlogs}
            
            >
                <Container>

              
                <h6>Blogs</h6>
                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {blog}
                       onChange = {(e) => setBlog(e.target.value)}
                       placeholder = {'Blog Title'}
                       />
                    </Col>
                </Row>

                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {blogDesc}
                       onChange = {(e) => setBlogDesc(e.target.value)}
                       placeholder = {'Blog Description'}
                       />
                    </Col>
                    <Col>
                       <Input type="file"
                        className="form-control"
                       name="image"
                       onChange = {handleBlogImages}
                       />
                    </Col>
                </Row>
        
           

            
                </Container>   
            </Modal>
        );
    }



    const renderCreateCounclierModal =() =>{
        return(

    
            <Modal  
                show = {createCouncllorModal}
                modalTitle = {"Create counsellor Section"}
                handleClose = {() => setCouncllorCreateModal(false)}
                onSubmit = {submitCounc}
            
            >
                <Container>

              
                <h6>counsellor</h6>
                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {counc}
                       onChange = {(e) => setCounc(e.target.value)}
                       placeholder = {'counsellor Title'}
                       />
                    </Col>
                </Row>

                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {degi}
                       onChange = {(e) => setDegi(e.target.value)}
                       placeholder = {'counsellor Degi'}
                       />
                    </Col>
                </Row>

                <Row>
                    <Col>
                       <Input
                       className="form-control=sm"
                       value = {councDesc}
                       onChange = {(e) => setCouncDesc(e.target.value)}
                       placeholder = {'counsellor Description'}
                       />
                    </Col>
                    <Col>
                       <Input type="file"
                        className="form-control"
                       name="image"
                       onChange = {handleCouncImages}
                       />
                    </Col>
                </Row>
        
           

            
                </Container>   
            </Modal>
        );
    }



  return(
      <Layout sidebar>
     {
        landing.landings ? <section className='mainPageCont'>
       
        <div className='bannerArea'>
           <h5 className='headingStyle'>Banner Section</h5>
           <h5 className='rounded1'><IoMdAdd onClick ={(e) => setCreateModal(true)}/></h5>
          </div> 
          <br/>
          <div className='bannerArea'>
           <h5 className='headingStyle'>Service Section</h5>
           <h5 className='rounded1'><IoMdAdd onClick ={(e) => setServiceCreateModal(true)}/></h5>
          </div> 
   
          <div className='allBanner'>
          <div className='banLeft'>
          <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
           
            <th>Action</th>
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
            
         {
            landing?.landings[0]?.headBannerSection?.map((head, index) => (
                <tr>
                    <td>{index + 1}</td>
                <td>  <img className='imgPnh' src={generatePublicUrl(head.img1)} alt=""/></td>
                
                <td>{head.title1}</td>
                <td>{head.subtitle1}</td>
                <td><button className='deleteBtn' onClick={() => {deleteBannerById(head._id)}}>Delete</button> <button className='detailsBtn'>Details</button></td>
                </tr>
            ))
         }
           {/* <tr>
         
           </tr> */}
        </tbody>
      </Table>
                       </div>
                       <div className='banRight'>
                       <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
           
            <th>Action</th>
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
            
         {
            landing?.landings[0]?.ourServices?.map((head, index) => (
                <tr>
                    <td>{index + 1}</td>
                <td>  <img className='imgPnh' src={generatePublicUrl(head.image)} alt=""/></td>
                
                <td>{head.title}</td>
                <td>{head.description}</td>
                <td><button className='deleteBtn' onClick={() => {deleteServiceById(head._id)}}>Delete</button> <button className='detailsBtn'>Details</button></td>
                </tr>
            ))
         }
           {/* <tr>
         
           </tr> */}
        </tbody>
      </Table>  
                       </div>
          </div>

          <br/>
          <div className='bannerArea'>
           <h5 className='headingStyle'>Blog Section</h5>
           <h5 className='rounded1'><IoMdAdd onClick ={(e) => setBlogCreateModal(true)}/></h5>
          </div> 
          <br/>
          <div className='bannerArea'>
           <h5 className='headingStyle'>counsellor Section</h5>
           <h5 className='rounded1'><IoMdAdd onClick ={(e) => setCouncllorCreateModal(true)}/></h5>
          </div> 
          <br/>

          <div className='allBanner'>
          <div className='banLeft'>
          <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
            <th>Date</th>
           
            <th>Action</th>
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
            
         {
            landing?.landings[0]?.blog?.map((head, index) => (
                <tr>
                    <td>{index + 1}</td>
                <td>  <img className='imgPnh' src={generatePublicUrl(head.image)} alt=""/></td>
                
                <td>{head.title}</td>
                <td>{head.description}</td>
                <td>{moment(head.date).format("MMM Do YY")}</td>
                <td><button className='deleteBtn' onClick={() => {deleteBlogeById(head._id)}}>Delete</button> <button className='detailsBtn'>Details</button></td>
                </tr>
            ))
         }
           {/* <tr>
         
           </tr> */}
        </tbody>
      </Table>
                       </div>
                       <div className='banRight'>
                       <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Title</th>
            <th>Description</th>
            <th>Designation</th>
            <th>Action</th>
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
            
         {
            landing?.landings[0]?.ourCounciller?.map((head, index) => (
                <tr>
                    <td>{index + 1}</td>
                <td>  <img className='imgPnh' src={generatePublicUrl(head.image)} alt=""/></td>
                
                <td>{head.name}</td>
                <td>{head.about}</td>
                <td>{head.designation}</td>
                <td><button className='deleteBtn' onClick={() => {deleteCouncById(head._id)}}>Delete</button> <button className='detailsBtn'>Details</button></td>
                </tr>
            ))
         }
           {/* <tr>
         
           </tr> */}
        </tbody>
      </Table>  
                       </div>
          </div>
          <br/>
          
         </section> : 
         <section className='ld'>
            <div class="banter-loader">
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
  <div class="banter-loader__box"></div>
</div>
         </section>
     }
      {renderCreatePageModal()}
      {renderCreateServiceModal()}
      {renderCreateBlogModal()}
      {renderCreateCounclierModal()}
      </Layout>
   )

  }

export default NewPage;