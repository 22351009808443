
import axios from "../helpers/axios";
import { authConstants, userConstants } from "./constants"








export const SignUp = (user) =>{
    return async (dispatch) =>{

        dispatch({type: userConstants.USER_REGISTER_REQUEST});

        const res = await axios.post(`/admin/signup`, {
            ...user
        });


        if(res.status === 200){
            const { message } = res.data;
        
            dispatch({
              
                type:userConstants.USER_REGISTER_SUCCESS,
                payload:{
                 message
                }

            });
        }else{
            if(res.status === 400){
                dispatch({

                    type: userConstants.USER_REGISTER_FAILURE,
                    payload:{error: res.data.error}

                });
            }
        }



    
    }
}

export const getUsers = () => {
    return async (dispatch) => {
      try {
        dispatch({ type: userConstants.USER_DATA_REQUEST });
        const res = await axios.get(`/allusers`);
      //   console.log(res, "daaaaaaaaaaaaaaaa");
        if (res.status === 200) {
          console.log(res.data.users, "daaaaaaaaaaaaaaaa");
          const { users } = res.data;
  
          console.log(users);
          
          dispatch({
            type: userConstants.USER_DATA_SUCCESS,
            payload: { users: res.data },
          });
        } else {
          dispatch({ type: userConstants.USER_DATA_FAILURE });
        }
      } catch (error) {
        console.log(error);
      }
    };
  };