import { withdrawConstants } from "../actions/constants";

const initialState ={
    withdraw : []
};

export default (state = initialState, action) =>{
    switch(action.type){
        case withdrawConstants.GET_ALL_WITHDRAW_SUCCESS:
            state={
                ...state,
                withdraw: action.payload.withdraw
            }
            break;
    }
    return state;
}