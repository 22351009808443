import React, {useMemo, useEffect, useState } from 'react'
import Layout from '../../components/Layout';
import {Table} from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import "./style.css";
import { getUsers } from '../../actions';
import moment from "moment";
import axios from '../../helpers/axios';
import { api } from '../../urlConfig';




const Home = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  const category = useSelector(state => state.category);
  const [tJobs, setTjobs] = useState([]);
  const [slots, setSlots] = useState([]);
  const [contact, setContact] = useState([]);

  console.log(category);
  


  const jobs = async() => {
    let totalJob = await axios.get(`${api}/job/get`);
    console.log(totalJob);
    setTjobs(totalJob?.data || [])
    
  }


  const book = async() => {
    let totaslots = await axios.get(`${api}/get/slots`);
    console.log(totaslots?.data);
    setSlots(totaslots?.data || [])
    // setTjobs(totalJob?.data || [])
    
  }

  const contactUs = async() => {
    let totaslots = await axios.get(`${api}/get/contact`);
    console.log(totaslots?.data);
    // setSlots(totaslots?.data || [])
    setContact(totaslots?.data || [])
    
  }





  console.log(user);



let userData = user.users;

console.log(userData?.users);


let filteredUsers = userData?.users?.filter(us => us.role === "user");
let filteredRecruiter = userData?.users?.filter(us => us.role === "recruiter");
console.log(userData, "datttaa");


const users = userData?.users || [];

const recentWeekCount = useMemo(() => {
    const now = new Date();
    
    // Calculate the start of the current week (7 days ago)
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);

    // Filter users created within the last 7 days and count them
    return filteredUsers?.filter(user => new Date(user.createdAt) >= oneWeekAgo).length;
}, [filteredUsers]);


const recentWeekCountRec = useMemo(() => {
  const now = new Date();
  
  // Calculate the start of the current week (7 days ago)
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(now.getDate() - 7);

  // Filter users created within the last 7 days and count them
  return filteredRecruiter?.filter(user => new Date(user.createdAt) >= oneWeekAgo).length;
}, [filteredRecruiter]);

  useEffect(() => {
    dispatch(getUsers());
  },[auth])

  useEffect(() => {
    jobs();
    book();
    contactUs();
  },[]);


  const recentWeekCountJob = useMemo(() => {
    const now = new Date();
    
    // Calculate the start of the current week (7 days ago)
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);

    // Filter users created within the last 7 days and count them
    return tJobs?.filter(user => new Date(user.createdAt) >= oneWeekAgo).length;
}, [tJobs]);



  const verfyData = async(id)=>{
    axios.post(`${api}/admin/verify/${id}`).then(res => {
      if(res) {
          dispatch(getUsers());
      }
     })
  }

  
  


  return(
    <Layout sidebar>
      
      <section className='homeCont'>
           <div className='homeInner'>
               <div className='homeCard'>
                <div className='homeUp'>
                <h1>Total users</h1>
                <p>{filteredUsers?.length}</p>
                </div>
                <div className='week'>
                  <p>Weekly Count</p>
                  <p>{recentWeekCount}</p>
                </div>
               </div>
               <div className='homeCard'>
                <div className='homeUp'>
                <h1>Total recruiters</h1>
                <p>{filteredRecruiter?.length}</p>
                </div>
                <div className='week'>
                  <p>Weekly Count</p>
                  <p>{recentWeekCountRec}</p>
                </div>
               </div>
               <div className='homeCard'>
                <div className='homeUp'>
                <h1>Total skills</h1>
                <p>{category?.categories?.length}</p>
                </div>
                <div className='week'>
                  <p>Total added</p>
                  <p>{category?.categories?.length}</p>
                </div>
               </div>
               <div className='homeCard'>
                <div className='homeUp'>
                <h1>Job posts</h1>
                <p>{tJobs?.length}</p>
                </div>
                <div className='week'>
                  <p>Weekly Count</p>
                  <p>{recentWeekCountJob}</p>
                </div>
               </div> 
           </div>
           <div className='gripContainer'>
                <div className='gripLeft'>
                <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>name</th>
            <th>email</th>
            <th>role</th>
            <th>Date</th>
            <th>Action</th>
            {/* <th></th> */}
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>


      
          
       
          {
            filteredRecruiter?.map((us, index) =>(
              <tr>
                <td>{index+1}</td>
              <td>{us.firstName} {us.lastNAme}</td>
              <td>{us.email}</td>
              <td>{us.role}</td>
              <td>  {moment(us.createdAt).format("MMM Do YY") }</td>
              <td>{us.role === "recruiter" ? us.adminVerified ? <button className='veriedBtn'>Verified</button> : <button onClick={() => verfyData(us._id)} className='verifyBtn'>Verify</button>: null} <button className='blockBtn'>Block</button></td>
              </tr>
              
            ))
          }
  
  
          
        </tbody>
      </Table>
                </div>
                <div className='gripRight'>
                <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>name</th>
            <th>email</th>
            <th>role</th>
            <th>Date</th>
            <th>Action</th>
            {/* <th></th> */}
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>


      
          
       
          {
            filteredUsers?.map((us, index) =>(
              <tr>
                <td>{index+1}</td>
              <td>{us.firstName} {us.lastNAme}</td>
              <td>{us.email}</td>
              <td>{us.role}</td>
              <td>  {moment(us.createdAt).format("MMM Do YY") }</td>
              <td>{us.role === "recruiter" ? us.adminVerified ? <button className='veriedBtn'>Verified</button> : <button onClick={() => verfyData(us._id)} className='verifyBtn'>Verify</button>: null} <button className='blockBtn'>Block</button></td>
              </tr>
              
            ))
          }
  
  
          
        </tbody>
      </Table>
                </div>
           </div>



          <div className='expert'>
          <h2>Expert booking details</h2>
          </div>

           <div className='gripContainerslot'>
                <div className='gripLeftslot'>
                <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>name</th>
            <th>email</th>
            <th>Expert name</th>
            <th>Expert Designation</th>
            <th>Phone number</th>
            <th>Slot date</th>
            {/* <th>Expert name</th> */}
            {/* <th></th> */}
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>


      
          
       
          {
            slots?.map((us, index) =>(
              <tr>
                <td>{index+1}</td>
              <td>{us.name}</td>
              <td>{us.email}</td>
              <td>{us?.counselorName}</td>
              <td>{us?.counselorDegi}</td>
              <td>{us?.phNumber}</td>
              <td>  {moment(us.slotDate).format("MMM Do YY") }</td>
              {/* <td>{us.role === "recruiter" ? us.adminVerified ? <button className='veriedBtn'>Verified</button> : <button onClick={() => verfyData(us._id)} className='verifyBtn'>Verify</button>: null} <button className='blockBtn'>Block</button></td> */}
              </tr>
              
            ))
          }
  
  
          
        </tbody>
      </Table>
                </div>
      
           </div>


           <div className='expert'>
          <h2>Contact us details</h2>
          </div>

           <div className='gripContainerslot'>
                <div className='gripLeftslot'>
                <Table style={{ fontSize: 12 }} responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>name</th>
            <th>email</th>
            <th>query</th>
            <th>Expert Designation</th>
            <th>Phone number</th>
            <th>Slot date</th>
            {/* <th>Expert name</th> */}
            {/* <th></th> */}
            {/* <th>Category</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>


      
          
       
          {
            contact?.map((us, index) =>(
              <tr>
                <td>{index+1}</td>
              <td>{us.name}</td>
              <td>{us.email}</td>
              <td>{us?.text}</td>
              <td>{us?.counselorDegi}</td>
              <td>{us?.phNumber}</td>
              <td>  {moment(us.createdAt).format("MMM Do YY") }</td>
              {/* <td>{us.role === "recruiter" ? us.adminVerified ? <button className='veriedBtn'>Verified</button> : <button onClick={() => verfyData(us._id)} className='verifyBtn'>Verify</button>: null} <button className='blockBtn'>Block</button></td> */}
              </tr>
              
            ))
          }
  
  
          
        </tbody>
      </Table>
                </div>
      
           </div>
      </section>
  
</Layout>
   )
   

 }
 
 const styles = {
  pieContainer: {
    width: "40%",
    height: "40%",
    top: "51%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    
  },
  relative: {
    position: "relative"
  }
};
export default Home