import axios from "../helpers/axios";
import { bannerConstants } from "./constants";

// new action
export const getBanners = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: bannerConstants.GET_ALL_BANNERS_REQUIEST });
      const res = await axios.get(`gethomebanner`);

      if (res.status === 200) {
      
        const { banners } = res.data;
      
        dispatch({
          type: bannerConstants.GET_ALL_BANNERS_SUCCESS,
          payload: { banners },
        });
        console.log(res.data);
      } else {
        dispatch({ type: bannerConstants.GET_ALL_BANNERS_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const addBanner = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: bannerConstants.ADD_BANNER_REQUEST });
      const res = await axios.post(`homebanner/create`, form);
      if (res.status === 201) {
        dispatch({ type: bannerConstants.ADD_BANNER_SUCCESS });
          dispatch(getBanners());
      } else {
        dispatch({ type: bannerConstants.ADD_BANNER_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const deleteBannerById = (hid) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`deleteBanner`, {
         hid 
      });
      dispatch({ type: bannerConstants.DELETE_BANNERS_BY_ID_REQUEST });
      if (res.status === 202) {
        dispatch({ type: bannerConstants.DELETE_BANNERS_BY_ID_SUCCESS });
        dispatch(getBanners());
      } else {
        const { error } = res.data;
        dispatch({
          type: bannerConstants.DELETE_BANNERS_BY_ID_FAILURE,
          payload: {
            error,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};