import { landingConstants, productConstants } from "../actions/constants";

const initialState ={
    landings : []
};

export default (state = initialState, action) =>{
    switch(action.type){
        case landingConstants.LANDING_SUCCESS:
            state={
                ...state,
                landings: action.payload.landings
            }
            break;
    }
    return state;
}