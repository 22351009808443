import { categoryConstants } from "../actions/constants";

const initState = {
    categories: [],
    error: null,
    loading: false
};

// Helper function to remove a category and its children
const removeCategory = (categoryId, categories) => {
    // Filter out the category with the specified ID
    return categories
        .filter(category => category._id !== categoryId)
        .map(category => ({
            ...category,
            children: category.children ? removeCategory(categoryId, category.children) : []
        }));
};


const buildNewCategories = (parentId, categories, category) => {
    let myCategories = [];

    if (parentId === undefined) {
        return [
            ...categories,
            {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                type: category.type,
                children: []
            }
        ];
    }

    for (let cat of categories) {
        if (cat._id === parentId) {
            const newCategory = {
                _id: category._id,
                name: category.name,
                slug: category.slug,
                parentId: category.parentId,
                type: category.type,
                children: []
            };

            myCategories.push({
                ...cat,
                children: cat.children.length > 0 ? [...cat.children, newCategory] : [newCategory]
            });
        } else {
            myCategories.push({
                ...cat,
                children: cat.children ? buildNewCategories(parentId, cat.children, category) : []
            });
        }
    }

    return myCategories;
};

export default (state = initState, action) => {
    switch (action.type) {
        case categoryConstants.GET_ALL_CATEGORIES_SUCCESS:
            state = {
                ...state,
                categories: action.payload.categories
            };
            break;

        case categoryConstants.ADD_NEW_CATEGORY_REQUIEST:
            state = {
                ...state,
                loading: true
            };
            break;

        case categoryConstants.ADD_NEW_CATEGORY_SUCCESS:
            const category = action.payload.category;
            const updatedCategories = buildNewCategories(category.parentId, state.categories, category);
            state = {
                ...state,
                categories: updatedCategories,
                loading: false
            };
            break;

        case categoryConstants.ADD_NEW_CATEGORY_FAILURE:
            state = {
                ...initState,
                loading: false,
                error: action.payload.error
            };
            break;

        case categoryConstants.UPDATE_CATEGORIES_REQUIEST:
            state = {
                ...state,
                loading: true
            };
            break;

        case categoryConstants.UPDATE_CATEGORIES_SUCCESS:
            state = {
                ...state,
                loading: false
            };
            break;

        case categoryConstants.UPDATE_CATEGORIES_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            };
            break;

        case categoryConstants.DELETE_CATEGORIES_REQUIEST:
            state = {
                ...state,
                loading: true
            };
            break;

        case categoryConstants.DELETE_CATEGORIES_SUCCESS:
            const categoryId = action.payload.category; // id of the category to delete
            const categoriesAfterDeletion = removeCategory(categoryId, state.categories);
            const updatedCategories1 = action.payload.categories;
            console.log("Categories after deletion:", categoriesAfterDeletion);
            state = {
                ...state,
                categories: categoriesAfterDeletion,
                loading: false
            };
            break;

        case categoryConstants.DELETE_CATEGORIES_FAILURE:
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            };
            break;

        default:
            break;
    }
    return state;
};
