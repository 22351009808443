export const authConstants ={
    LOGIN_REQUIEST : 'LOGIN_REQUIEST',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT_REQUIEST: ' LOGOUT_REQUIEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE'
}

export const landingConstants ={

  LANDING_REQUEST: 'LANDING_REQUEST',
  LANDING_SUCCESS: 'LANDING_SUCCESS',
  LANDING_FAILURE: 'LANDING_FAILURE',

  LANDING_ADD_REQUEST: 'LANDING_ADD_REQUEST',
  LANDING_ADD_SUCESS: 'LANDING_ADD_SUCESS',
  LANDING_ADD_FAILURE: 'LANDING_ADD_FAILURE',

  LANDING_ADD_BLOG_REQUEST: 'LANDING_ADD_BLOG_REQUEST',
  LANDING_ADD_BLOG_SUCESS: 'LANDING_ADD_BLOG_SUCESS',
  LANDING_ADD_BLOG_FAILURE: 'LANDING_ADD_BLOG_FAILURE',

  LANDING_ADD_COUNC_REQUEST: 'LANDING_ADD_COUNC_REQUEST',
  LANDING_ADD_COUNC_SUCESS: 'LANDING_ADD_COUNC_SUCESS',
  LANDING_ADD_COUNC_FAILURE: 'LANDING_ADD_COUNC_FAILURE',

  LANDING_ADD_SERVICE_REQUEST: 'LANDING_ADD_SERVICE_REQUEST',
  LANDING_ADD_SERVICE_SUCESS: 'LANDING_ADD_SERVICE_SUCESS',
  LANDING_ADD_SERVICE_FAILURE: 'LANDING_ADD_SERVICE_FAILURE',

}

export const userConstants ={

    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

    USER_DATA_REQUEST: 'USER_DATA_REQUEST',
    USER_DATA_SUCCESS: 'USER_DATA_SUCCESS',
    USER_DATA_FAILURE:  'USER_DATA_FAILURE'

}

export const categoryConstants ={

 GET_ALL_CATEGORIES_REQUIEST: 'GET_ALL_CATEGORIES_REQUIEST',
 GET_ALL_CATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_SUCCESS',
 GET_ALL_CATEGORIES_FAILURE: 'GET_ALL_CATEGORIES_FAILURE',
 ADD_NEW_CATEGORY_REQUIEST: ' ADD_NEW_CATEGORY_REQUIEST',
 ADD_NEW_CATEGORY_SUCCESS: ' ADD_NEW_CATEGORY_SUCCESS',
 ADD_NEW_CATEGORY_FAILURE: ' ADD_NEW_CATEGORY_FAILURE',
UPDATE_CATEGORIES_REQUIEST: 'UPDATE_CATEGORIES_REQUIEST',
UPDATE_CATEGORIES_SUCCESS: 'UPDATE_CATEGORIES_SUCCESS',
UPDATE_CATEGORIES_FAILURE: 'UPDATE_CATEGORIES_FAILURE',
DELETE_CATEGORIES_REQUIEST: 'DELETE_CATEGORIES_REQUIEST',
DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
DELETE_CATEGORIES_FAILURE: 'DELETE_CATEGORIES_FAILURE'
}


export const productConstants ={
    GET_ALL_PRODUCTS_REQUIEST: ' GET_ALL_PRODUCTS_REQUIEST',
    GET_ALL_PRODUCTS_SUCCESS: ' GET_ALL_PRODUCTS_SUCCESS',
    GET_ALL_PRODUCTS_FAILURE: ' GET_ALL_PRODUCTS_FAILURE',


    GET_ALL_WITHDRAW_REQUIEST: ' GET_ALL_WITHDRAW_REQUIEST',
    GET_ALL_WITHDRAW_SUCCESS: ' GET_ALL_WITHDRAW_SUCCESS',
    GET_ALL_WITHDRAW_FAILURE: ' GET_ALL_WITHDRAW_FAILURE',

    DELETE_PRODUCT_BY_ID_REQUEST: "DELETE_PRODUCT_BY_ID_REQUEST",
    DELETE_PRODUCT_BY_ID_SUCCESS: "DELETE_PRODUCT_BY_ID_SUCCESS",
    DELETE_PRODUCT_BY_ID_FAILURE: "DELETE_PRODUCT_BY_ID_FAILURE",
  
    ADD_PRODUCT_REQUEST: "ADD_PRODUCT_REQUEST",
    ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_REQUEST",
    ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",

    TEST_PRODUCT_REQUEST: "TEST_PRODUCT_REQUEST",
    TEST_PRODUCT_SUCCESS: "TEST_PRODUCT_SUCCESS",
    TEST_PRODUCT_FAILURE: "TEST_PRODUCT_FAILURE",
}



export const projectConstants ={
  GET_ALL_PROJECT_REQUIEST: ' GET_ALL_PROJECT_REQUIEST',
  GET_ALL_PROJECT_SUCCESS: ' GET_ALL_PROJECT_SUCCESS',
  GET_ALL_PROJECT_FAILURE: ' GET_ALL_PROJECT_FAILURE',
  

  ADD_PROject_REQUEST: "ADD_PROject_REQUEST",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_PROJECT_FAILURE: "ADD_PROJECT_FAILURE",

  ADD_SESSION_REQUEST: "ADD_SESSION_REQUEST",
  ADD_SESSION_SUCCESS: "ADD_SESSION_SUCCESS",
  ADD_SESSION_FAILURE: "ADD_SESSION_FAILURE",


  ADD_POST_REQUEST: "ADD_POST_REQUEST",
  ADD_POST_SUCCESS: "ADD_POST_SUCCESS",
  ADD_POST_FAILURE: "ADD_POST_FAILURE",

  GET_POST_REQUEST: "GET_POST_REQUEST",
  GET_POST_SUCCESS: "GET_POST_SUCCESS",
  GET_POST_FAILURE: "GET_POST_FAILURE",

}


export const withdrawConstants ={


  GET_ALL_WITHDRAW_REQUIEST: ' GET_ALL_WITHDRAW_REQUIEST',
  GET_ALL_WITHDRAW_SUCCESS: ' GET_ALL_WITHDRAW_SUCCESS',
  GET_ALL_WITHDRAW_FAILURE: ' GET_ALL_WITHDRAW_FAILURE',




}


export const initialDtataConstants ={

 GET_ALL_INITIAL_DATA_REQUIEST: ' GET_ALL_INITIAL_DATA_REQUIEST',
 GET_ALL_INITIAL_DATA_SUCCESS: ' GET_ALL_INITIAL_DATA_SUCCESS',
 GET_ALL_INITIAL_DATA_FAILURE: ' GET_ALL_INITIAL_DATA_FAILURE'
   
   }

   
export const pageConstants ={

  CREATE_PAGE_REQUIEST:' CREATE_PAGE_REQUIEST',
  CREATE_PAGE_SUCCESS:' CREATE_PAGE_SUCCESS',
  CREATE_PAGE_FAILURE:' CREATE_PAGE_FAILURE',
      
      }

      export const orderConstants = {
        GET_CUSTOMER_ORDER_REQUEST: "GET_CUSTOMER_ORDER_REQUEST",
        GET_CUSTOMER_ORDER_SUCCESS: "GET_CUSTOMER_ORDER_SUCCESS",
        GET_CUSTOMER_ORDER_FAILURE: "GET_CUSTOMER_ORDER_FAILURE",
      
        UPDATE_CUSTOMER_ORDER_REQUEST: "UPDATE_CUSTOMER_ORDER_REQUEST",
        UPDATE_CUSTOMER_ORDER_SUCCESS: "UPDATE_CUSTOMER_ORDER_SUCCESS",
        UPDATE_CUSTOMER_ORDER_FAILURE: "UPDATE_CUSTOMER_ORDER_FAILURE",
      };


      export const bannerConstants ={
        GET_ALL_BANNERS_REQUIEST: ' GET_ALL_BANNERS_REQUIEST',
        GET_ALL_BANNERS_SUCCESS: ' GET_ALL_BANNERS_SUCCESS',
        GET_ALL_BANNERS_FAILURE: ' GET_ALL_PRODUCTS_FAILURE',
    
        DELETE_BANNERS_BY_ID_REQUEST: "DELETE_BANNERS_BY_ID_REQUEST",
        DELETE_BANNERS_BY_ID_SUCCESS: "DELETE_BANNERS_BY_ID_SUCCESS",
        DELETE_BANNERS_BY_ID_FAILURE: "DELETE_BANNERS_BY_ID_FAILURE",
      
        ADD_BANNER_REQUEST: "ADD_BANNER_REQUEST",
        ADD_BANNER_SUCCESS: "ADD_BANNER_REQUEST",
        ADD_BANNER_FAILURE: "ADD_BANNER_FAILURE",
    }
    