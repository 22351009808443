import React from 'react';
import { Modal, Button } from 'react-bootstrap';

/**
* @author
* @function Modal
**/

const NewModal = (props) => {
  return(
    <Modal size={props.size} show={props.show} onHide={props.handleClose} style={{ border: 'none'}}  aria-labelledby="contained-modal-title-vcenter"
    centered> 
    <Modal.Header style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: '1px solid #4A4A4A', borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }} closeButton>
      <Modal.Title style={{padding: "10px", fontSize: "14px", fontWeight: "bold"}}>{props.modalTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{backgroundColor: '#313131', border: 'none'}}>
         {props.children}
    </Modal.Body>
    <Modal.Footer style={{ borderTop: '1px solid #4A4A4A', borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", backgroundColor: '#313131' }}>
          {
            props.buttons ? props.buttons.map((btn, index )=> 
              <Button key={index} variant={btn.color} onClick={btn.onClick}>
              {
                btn.label
              }
            </Button>):
                <Button  variant="primary" {...props} style={{backgroundColor: 'black', border: 'none', padding: "8px 30px"}} className="btn-sm" onClick={props.onSubmit}>
                Save
              </Button>
          }
  
    </Modal.Footer>
  </Modal>
   )

 }

export default NewModal