import { projectConstants } from "../actions/constants";

const initialState ={
    projects : [],
    postData: [],
    result: [],
};

export default (state = initialState, action) =>{
    switch(action.type){
        case projectConstants.GET_ALL_PROJECT_SUCCESS:
            state={
                ...state,
                projects: action.payload.projects
            }
            break;
            case projectConstants.GET_POST_SUCCESS:
                state={
                    ...state,
                    postData: action.payload.postData,
                    result: action.payload.result
                }
                break;
    }
    return state;
}