import axios from "../helpers/axios";
import { landingConstants, productConstants } from "./constants";

// new action
 const getLanding = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: landingConstants.LANDING_REQUEST });
      const res = await axios.get(`/getalllanding`);
    //   console.log(res, "daaaaaaaaaaaaaaaa");
      if (res.status === 200) {
        console.log(res.data, "daaaaaaaaaaaaaaaa");
        const { landings } = res.data;

        console.log(landings);
        
        dispatch({
          type: landingConstants.LANDING_SUCCESS,
          payload: { landings: res.data },
        });
      } else {
        dispatch({ type: landingConstants.LANDING_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const addLanding = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: landingConstants.LANDING_ADD_REQUEST });
      const res = await axios.post(`/admin/headbanner`, form);
      if (res.status === 200) {
        dispatch({ type: landingConstants.LANDING_ADD_SUCESS });
        dispatch(getLanding());
      } else {
        dispatch({ type: landingConstants.LANDING_ADD_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const addService = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: landingConstants.LANDING_ADD_SERVICE_REQUEST});
      const res = await axios.post(`/admin/ourServices`, form);
      if (res.status === 200) {
        dispatch({ type: landingConstants.LANDING_ADD_SERVICE_SUCESS });
        dispatch(getLanding());
      } else {
        dispatch({ type: landingConstants.LANDING_ADD_SERVICE_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addBlog = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: landingConstants.LANDING_ADD_BLOG_REQUEST});
      const res = await axios.post(`/admin/blog`, form);
      if (res.status === 200) {
        dispatch({ type: landingConstants.LANDING_ADD_BLOG_SUCESS });
        dispatch(getLanding());
      } else {
        dispatch({ type: landingConstants.LANDING_ADD_BLOG_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addCounc = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: landingConstants.LANDING_ADD_COUNC_REQUEST});
      const res = await axios.post(`/admin/ourCounciller`, form);
      if (res.status === 200) {
        dispatch({ type: landingConstants.LANDING_ADD_COUNC_SUCESS });
        dispatch(getLanding());
      } else {
        dispatch({ type: landingConstants.LANDING_ADD_COUNC_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export {
   getLanding
}



