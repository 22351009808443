import axios from "../helpers/axios";
import { projectConstants } from "./constants";
import { getInitialData } from "./initialData.action";

// new action
const getProjects = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: projectConstants.GET_ALL_PROJECT_REQUIEST });
      const res = await axios.post(`/admin/getproject`);
      console.log(res, "action project");
      if (res.status === 200) {
        
        const { projects } = res.data;
        dispatch({
          type: projectConstants.GET_ALL_PROJECT_SUCCESS,
          payload: { projects },
        });
      } else {
        dispatch({ type: projectConstants.GET_ALL_PROJECT_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};



// modified actrion
export const addProject = (form) => {
  return async (dispatch) => {
    try {
      dispatch({ type: projectConstants.ADD_PROject_REQUEST });
      const res = await axios.post(`/admin/project`, form);
      if (res.status === 201) {
        console.log(res, "test add project");
      
        dispatch({ type: projectConstants.ADD_PROJECT_SUCCESS });
        dispatch(getInitialData())
        
      } else {
        dispatch({ type: projectConstants.ADD_PROJECT_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const addSession = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: projectConstants.ADD_SESSION_REQUEST });
      const res = await axios.post(`/admin/season`, payload);
      if (res.status === 201) {
        console.log(res, "test add project");
      
        dispatch({ type: projectConstants.ADD_SESSION_SUCCESS });
        dispatch(getInitialData())
        
      } else {
        dispatch({ type: projectConstants.ADD_SESSION_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const addPost = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: projectConstants.ADD_POST_REQUEST });
      const res = await axios.post(`/admin/post`, payload);
      if (res.status === 201) {
        console.log(res, "test add project");
      
        dispatch({ type: projectConstants.ADD_POST_SUCCESS });
        dispatch(getInitialData())
        return res;
        
      } else {
        dispatch({ type: projectConstants.ADD_POST_FAILURE });
      }
    } catch (error) {
      console.log(error);
    }
  };
};






// export const testProduct = (payloadTest) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: productConstants.TEST_PRODUCT_REQUEST });
//       const res = await axios.post(`product/tested`, payloadTest);
//       if (res.status === 201) {
//         dispatch({ type: productConstants.TEST_PRODUCT_SUCCESS });
//         dispatch(getProducts());
//       } else {
//         dispatch({ type: productConstants.ADD_PRODUCT_FAILURE });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

// new action
// export const deleteProductById = (payload) => {
//   return async (dispatch) => {
//     try {
//       const res = await axios.delete(`product/deleteProductById`, {
//         data: { payload },
//       });
//       dispatch({ type: productConstants.DELETE_PRODUCT_BY_ID_REQUEST });
//       if (res.status === 202) {
//         dispatch({ type: productConstants.DELETE_PRODUCT_BY_ID_SUCCESS });
//         dispatch(getProducts());
//       } else {
//         const { error } = res.data;
//         dispatch({
//           type: productConstants.DELETE_PRODUCT_BY_ID_FAILURE,
//           payload: {
//             error,
//           },
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };






export const getPostDetailsByParams = (payload) => {
  return async dispatch => {
      dispatch({ type: projectConstants.GET_POST_REQUEST });
      let res;
      try {
          const { projectId, sessonId, postId } = payload.params;
          console.log(payload.params);
          res = await axios.get(`/get-rcord/${projectId}/${sessonId}/${postId}`);
          console.log(res);
          console.log(res.project, payload, "projects");
          let data = res.data
          dispatch({
              type: projectConstants.GET_POST_SUCCESS,
              payload: { postData: data.data, result: data.result}
          });
      

      } catch(error) {
          console.log(error);
          dispatch({
              type: projectConstants.GET_POST_FAILURE,
              payload: { error: res.data.error }
          });
      }

  }
}


export {
    getProjects
}